import React from "react";
import NavigationMenu from "../../../navigationmenu/src/NavigationMenu.web";
import Navbar from "../../../settings2/src/Navbar.web";
import "./clientListing.css";
import ClientListingController, { Props } from "./ClientListingController.web";
import { TabContext, TabPanel } from "@material-ui/lab";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Tab, Tabs } from "@material-ui/core";
import {
  closeIcon,
  filterIcon,
  plusIcon,
  searchIcon,
  sortIcon,
  trashIcon
} from "../assets";
import ClientListFilter from "./ClientListFilter.web";
import ActiveClientTable from "./ActiveClientTable.web";
import ClientListSort from "./ClientListSort.web";
import Strings from "../Strings";

export default class ClientListing extends ClientListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    window.scrollTo(0, 0);
    return (
      //Merge Engine DefaultContainer
      <>
        <div id="element" className="main-wrapper main-wrapper_listing">
          <NavigationMenu navigation={this.props.navigation} id={""} />
          <div className="listing_container_wrapper">
            <div className="listing_navbar_container">
              <Navbar navigation={this.props.navigation} id={""} />
            </div>
            <div className="listing_body_container">
              <div className="client_listing_header_wrapper">
                <div className="header_title_wrapper">
                  <h1 className="header_title_text">{Strings.clients}</h1>
                </div>
                <div
                  data-test-id="add-client"
                  className="header_add_client_wrapper"
                  onClick={this.addClient}
                >
                  <img
                    className="add_client_icon"
                    src={plusIcon}
                    alt="add_icon"
                  />
                  <p className="add_client_text">{Strings.registerNewClient}</p>
                </div>
              </div>
              <div className="clientlisitning-sub-header-container">
                <Tabs
                  value={this.state.tabIndex}
                  data-test-id="listingtabs"
                  onChange={this.handleTabChange}
                  classes={{
                    root: "listing_tabs_root",
                    indicator: "listing_tabs_indicator",
                    scroller: "listing_tabs_scroller"
                  }}
                >
                  <Tab
                    data-test-id="tabs-detail"
                    label={Strings.activeClients}
                    classes={{
                      root: "listing_tab_root",
                      selected: "listing_tab_selected"
                    }}
                  />
                  <Tab
                    data-test-id="tabs-contract"
                    label={Strings.inactiveClients}
                    classes={{
                      root: "listing_tab_root listing_tab_last",
                      selected: "listing_tab_selected"
                    }}
                  />
                </Tabs>

                <div className="listing-subheader-right-container">
                  {/* delete */}
                  <button
                    disabled={!this.state.select_user_delete.length}
                    data-test-id="delete-btn"
                    className="delete_btn"
                    onClick={this.showDeleteModalFlag}
                  >
                    <img src={trashIcon} alt="delete icon" />
                    <p className="hide-tabsmall-element">{Strings.deleteAll}</p>
                  </button>

                  {/* search */}
                  <div className="search_container hide-mobbig-element">
                    <img src={searchIcon} alt="searcIcon" />
                    <input
                      type="text"
                      placeholder={Strings.searchClient}
                      data-test-id="search-input"
                      onChange={this.handleChangeSearch}
                      value={this.state.searchQueryState.searchText}
                    />
                  </div>
                  {/* filter icon */}
                  <div className="client_listing_filter_container">
                    <button
                      className="filter_button hide-mobbig-element"
                      data-test-id="filter_button"
                      onClick={this.showFilterModal}
                    >
                      <img src={filterIcon} alt="filterIcon" />
                      <p className="hide-tabsmall-element">{Strings.filters}</p>
                    </button>
                    <img
                      className="filter_btn_icon_only"
                      src={filterIcon}
                      alt="filterIcon"
                      data-test-id="filter_button_responsive"
                      onClick={this.showFilterModal}
                    />

                    {this.state.showFilterModal && (
                      <ClickAwayListener
                        onClickAway={this.handleClickAwayFilter}
                      >
                        <div className="filter_modal_container">
                          <ClientListFilter
                            navigation={this.props.navigation}
                            searchQueryState={this.state.searchQueryState}
                            setSearchQueryState={this.setSearchQueryState}
                            showFilterModal={this.showFilterModal}
                            companyList={this.state.companyList}
                            cityList={this.state.cityList}
                          />
                        </div>
                      </ClickAwayListener>
                    )}
                  </div>
                  {/* sort icon */}
                  <div className="client_listing_sort_container">
                    <button
                      className="sort_button hide-mobbig-element"
                      data-test-id="sort_button"
                      onClick={this.showSortModal}
                    >
                      <img src={sortIcon} alt="sortIcon" />
                      <p className="hide-tabsmall-element">{Strings.sort}</p>
                    </button>
                    <img
                      data-test-id="sort_button_responsive"
                      onClick={this.showSortModal}
                      className="sort_btn_icon_only"
                      src={sortIcon}
                      alt="sortIcon"
                    />

                    {this.state.showSortrModal && (
                      <ClickAwayListener onClickAway={this.handleClickAwaySort}>
                        <div className="sort_modal_container">
                          <ClientListSort
                            navigation={this.props.navigation}
                            searchQueryState={this.state.searchQueryState}
                            setSearchQueryState={this.setSearchQueryState}
                          />
                        </div>
                      </ClickAwayListener>
                    )}
                  </div>
                </div>

                {/* search mobile */}
                <div className="search_container search_container_mob_big hide-mobbig-element">
                  <img src={searchIcon} alt="searcIcon" />
                  <input
                    type="text"
                    placeholder={Strings.searchClient}
                    data-test-id="search-input_responsive"
                    onChange={this.handleChangeSearch}
                    value={this.state.searchQueryState.searchText}
                  />
                </div>
              </div>

              <TabContext value={this.state.tabIndexString}>
                <TabPanel
                  value={this.state.tabIndexString}
                  className="client_table_wrapper"
                  classes={{ root: "listing_tabpanel_root" }}
                >
                  <ActiveClientTable
                    navigation={this.props.navigation}
                    clientListigData={this.state.clientListigData}
                    loader={this.state.apiloader}
                    searchQueryState={this.state.searchQueryState}
                    setSearchQueryState={this.setSearchQueryState}
                    selectUserDelete={this.state.select_user_delete}
                    setSelectUserForDelete={this.setSelectUserForDelete}
                    handleDelete={this.handleDelete}
                    handleViewAsClient={this.viewAsClient}
                  />
                </TabPanel>
              </TabContext>
            </div>
          </div>
        </div>
        {this.state.deleteModalFlag && (
          <div
            className="delete_modal_popup_wrapper"
            data-test-id="logoutPopupBox"
          >
            <div className="delete_modal_popup_container">
              <div className="delete_modal_modal_close">
                <img
                  src={closeIcon}
                  alt="cross"
                  data-test-id="closeModalImg"
                  onClick={this.hideDeleteModalFlag}
                />
              </div>
              <p className="delete_modal_text_message">
                {Strings.areYouSureDeleteHeader}
              </p>
              <p className="delete_modal_sub_text_message">
                {Strings.areYouSureDeleteSubheader1} <br />{" "}
                {Strings.areYouSureDeleteSubheader2}
              </p>

              <div className="delete_modal_button_container">
                <button
                  data-test-id="closeModal"
                  className="delete_modal_cancel_button"
                  onClick={this.hideDeleteModalFlag}
                >
                  {Strings.cancel}
                </button>
                <button
                  data-test-id="handleDelete"
                  className="delete_modal_logout_button"
                  onClick={() =>
                    this.handleDelete(this.state.select_user_delete)
                  }
                >
                  {Strings.delete}
                </button>
              </div>
            </div>
          </div>
        )}
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
